export default {
  "login_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome home, Petranesian!"])},
  "welcome_home_petranesian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advance your professional life."])},
  "login_anda_stay_connected_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in to access our full features, from posting vacancies to hiring our top graduates."])},
  "grow_Professionally_advance_your_professional_life_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grow professionally/Advance your professional life Build a career-boosting network with fellow alumni and future employers, right here at Petranesian Alumni Platform."])},
  "welcome_back_to_passion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recruit Petra Alumni for Your Company"])},
  "log_id_access_our_full_features_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Build a career-boosting network with fellow alumni and future employers, right here at PANNEL."])},
  "discover_top_talets_from_our_alumni_database": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover Top Talents From Our Alumni Database"])},
  "join_PANNEL_to_hire_our_best_graduates_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join Petranesian Alumni Platform to hire our best graduates to boost your company growth"])},
  "company_login_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome back to PANNEL"])},
  "company_login_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in to access our full features, from posting vacancies to hiring our top graduates"])},
  "company_register_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover Top Talents From Our Alumni Database"])},
  "company_register_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join PANNEL to hire our best graduates to boost your company growth"])},
  "menu": {
    "alumni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find Petranesians"])},
    "tracer_study": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tracer Study"])},
    "find_jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find Jobs"])},
    "find_talents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find Petranesians"])},
    "find_talents_submenu": {
      "inside_image": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Petranesian Alumni"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our vast graduate database helps you discover and bring top talents into your organization—from IT, creative, finance, to engineering. "])}
      },
      "submenu_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Petranesian Alumni"])},
      "submenu_list": {
        "alumni_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alumni Service"])},
        "alumni_business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alumni Business"])},
        "alumni_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alumni Product"])}
      }
    },
    "alumni_relations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alumni Relations"])},
    "alumni_relations_submenu": {
      "give_back": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Give Back Program"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support Indonesia’s future leaders through Petra Christian University"])}
      },
      "alumni_stories": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alumni Stories"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dive into our alumni’s career journeys, stellar achievements, and captivating love stories."])}
      }
    },
    "merchandise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merchandise"])},
    "digital-card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Petranesian Alumni Card"])},
    "newsinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News & Information"])},
    "sign_in_sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login / Register"])},
    "sign_in_sign_up_submenu": {
      "alumni": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Alumni"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stay connected. Keep your profile updated to get the most out of PANNEL."])}
      },
      "company": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Companies"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join PANNEL to hire our best graduates to boost your company growth."])}
      }
    },
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
    "sign_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log Out"])},
    "mobile": {
      "view_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Card"])},
      "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
      "account_submenu": {
        "view_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Card"])},
        "edit_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Profile"])},
        "view_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Profile"])},
        "education_activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education & Activities"])},
        "work_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work Experience"])},
        "your_business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Business"])},
        "your_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Products"])},
        "applications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applications"])},
        "bookmarked_job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bookmarked Jobs"])},
        "item_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Orders"])},
        "your_vacancies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Vacancies"])}
      }
    },
    "tutorial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutorial"])}
  },
  "tracerstudy": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Feedback Makes Us Better"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help us sustain years of excellence with a short survey"])}
  },
  "logout": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You Will Exit The App"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue?"])}
  },
  "forgot_password": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot Password"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your email, and an OTP code will be sent via email."])},
    "trouble": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trouble with your Account? Contact us "])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "step1": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])}
    },
    "step2": {
      "otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTP Code"])}
    },
    "step3": {
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
      "password_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password"])}
    },
    "success": {
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password has been changed successfully."])}
    }
  },
  "login": {
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "choose_alumni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Alumni"])},
    "choose_alumni_based_on_lecture_period_and_major_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Alumni Based On Lecture Period and Major First!"])},
    "choose_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select City"])},
    "choose_prody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Study Program"])},
    "choose_province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Province"])},
    "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Name"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue?"])},
    "data_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Not Found, Please Try Again."])},
    "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
    "birth_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birth Date"])},
    "birth_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birth Place"])},
    "graduation_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graduation Year"])},
    "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["here"])},
    "insert_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Address"])},
    "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "nim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NIM"])},
    "forgot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot Password?"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "password_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password"])},
    "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
    "please_insert_a_name_of_at_least_3_characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please insert your name with minimum 3 characters"])},
    "sign_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
    "trouble_register_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trouble register in? Contact us"])},
    "trouble_signin_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trouble logging in? Contact us"])},
    "wait_for_a_moment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wait for a moment"])},
    "year_of_entry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year of Entry"])},
    "you_cant_sign_in_with_admin_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can't Login with admin account"])},
    "you_will_do_the_registration_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You Will Do The Registration Process"])},
    "company": {
      "register_part1": {
        "error_messages": {
          "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company name is required"])},
          "bussiness_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bussiness field is required"])},
          "bussiness_scope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bussiness scope is required"])},
          "telp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company phone number is required"])},
          "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City and province is required"])},
          "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address is required"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description is required"])}
        },
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Name *"])},
        "id_mh_bussines_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Business Field *"])},
        "bussiness_scope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Business Scope *"])},
        "bussiness_scope_item": {
          "local": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local"])},
          "national": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["National"])},
          "multinational": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multinational / International"])}
        },
        "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website"])},
        "telp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Office Phone Number *"])},
        "id_mh_province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Province *"])},
        "id_mh_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select City *"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address *"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Logo"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Description *"])}
      },
      "register_part2": {
        "contact_person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Person *"])},
        "contact_person_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Person Position *"])},
        "contact_person_telp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Person Telephone *"])}
      },
      "register_part3": {
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email *"])},
        "email_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use official company email and not personal email"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password *"])},
        "password_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password *"])},
        "password_confirm_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password confirmation does not match"])}
      },
      "register_success": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully registered, <br />Please wait for the approval process from the Admin."])}
      }
    }
  },
  "main": {
    "search": {
      "no_data_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Data Found"])}
    },
    "alumni": {
      "alumni_profile_is_not_open_to_companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alumni profile is not open to companies!!"])},
      "work_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work Status"])},
      "work_status_options": {
        "employed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employed"])},
        "unemployed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open To Work"])}
      },
      "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
      "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepted"])},
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity"])},
      "activity_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity History"])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Age"])},
      "agency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agency"])},
      "all_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Status"])},
      "application_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application History"])},
      "apply_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply Filter"])},
      "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approved"])},
      "bookmark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bookmark Vacancy"])},
      "bookmarked_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bookmarked Successfully"])},
      "choose_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select City"])},
      "choose_city_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select province first"])},
      "choose_province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Province"])},
      "choose_prody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Program"])},
      "choose_skill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Skill"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
      "current_sallary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Salary"])},
      "view_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Profile"])},
      "edit_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit profile"])},
      "education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education"])},
      "explore_petranesian_top_talents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover Top Candidates for Your Company"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find the talent you’ve been looking for."])},
      "farthest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farthest"])},
      "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
      "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
      "find_petranesian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find Petranesians"])},
      "find_business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find Petranesians Business"])},
      "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis Kelamin"])},
      "get_yours_today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get yours today!"])},
      "graduation_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graduation Year"])},
      "select_graduation_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Graduation Year"])},
      "prioritize_unemployee_alumni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prioritize Open to Work"])},
      "is_top20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top 20"])},
      "last_education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Education"])},
      "latest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest"])},
      "list_alumni_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List Petranesians Product"])},
      "load_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load More"])},
      "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "no_activity_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Activity History"])},
      "no_alumni_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Petranesians Found :("])},
      "no_education_history_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Education History Added"])},
      "no_front_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Front Title"])},
      "gpa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GPA"])},
      "no_organizational_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Organizational Experience"])},
      "no_product_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Product Added"])},
      "no_title_behind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Title Behind"])},
      "no_work_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Work Experience"])},
      "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now"])},
      "open_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Card"])},
      "organizational": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizational"])},
      "organizational_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizational Experince"])},
      "petranesian_digital_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Petranesian Alumni Card"])},
      "point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Point"])},
      "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
      "professional_skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional Skills"])},
      "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Province"])},
      "prody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Study Program"])},
      "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejected"])},
      "seen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seen"])},
      "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
      "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show"])},
      "show_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Filters"])},
      "since": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Since"])},
      "skill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skill"])},
      "sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort"])},
      "start_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Now"])},
      "there_are_no_bookmark_yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no bookmark yet. Let's apply for a job more diligently :-)"])},
      "there_are_no_job_applications_yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no job applications yet. Let's apply for a job more diligently :-)"])},
      "till": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["till"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
      "unreal_works_made_by_petranesian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unreal works made by Petranesians"])},
      "vacancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacancy"])},
      "waiting_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting For Confirmation"])},
      "we_are_happy_to_know_your_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are happy to know your feedback"])},
      "work_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work Experience"])},
      "item_list_alumni": {
        "age_unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yo"])}
      },
      "filters": {
        "product_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])}
      },
      "switch_search": {
        "alumni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find Petranesians"])},
        "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find Business"])}
      }
    },
    "company": {
      "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
      "apply_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply Filter"])},
      "choose_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select City"])},
      "choose_province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Province"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
      "company_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Size"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
      "edit_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit profile"])},
      "my_vacancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Job Vacancies"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "find_top_company_around_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find Top Company Around You"])},
      "founded_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Founded In"])},
      "hiring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiring"])},
      "industry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Industry"])},
      "is_hiring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Hiring"])},
      "is_star_partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star Partner"])},
      "job_vacancies_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Vacancy At"])},
      "latest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest"])},
      "looking_for_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Looking for work? here is the right place"])},
      "more_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More Filters"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "no_company_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Company Result"])},
      "no_vacancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Vacancy"])},
      "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Province"])},
      "show_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Filter"])},
      "sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort"])},
      "till": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["till"])},
      "visit_website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visit Website"])},
      "item_company": {
        "star_partner_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["STAR PARTNER is a national/international level company that has been selected as a strategic partner for PCU."])}
      }
    },
    "survey": {
      "exit_study": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exit Study"])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
      "find_tracer_study": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find Tracer Study"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
      "survey_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["survey type"])},
      "thank_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank You"])},
      "tracer_study": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tracer Study"])},
      "you_have_filled_out_this_tracer_study_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have filled out this tracer study on"])}
    },
    "talent": {
      "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
      "agency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agency"])},
      "apply_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply Filter"])},
      "choose_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select City"])},
      "choose_province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Province"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
      "contact_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Information"])},
      "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee"])},
      "find_top_service_around_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find Petra Alumni Goods & Services"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From the F&B, fashion, to tech industry, explore products from Petranesian alumni"])},
      "freelance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freelance"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
      "looking_for_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From the F&B, fashion, to tech industry, explore products from Petranesian alumni"])},
      "no_service_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Service Found"])},
      "profile_alumni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile Alumni"])},
      "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Province"])},
      "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service"])},
      "show_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Filter"])}
    },
    "vacancy": {
      "academic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Academics"])},
      "active_recruiting_until": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Recruiting Until"])},
      "applied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applied"])},
      "apply_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply Filter"])},
      "apply_job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply Job"])},
      "apply_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply Now"])},
      "bachelor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bachelor"])},
      "choose_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select City"])},
      "choose_province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Province"])},
      "choose_your_document_for_this_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select your document for this application"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
      "company_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Size"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
      "cv_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CV First"])},
      "diploma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diploma"])},
      "doctor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctor"])},
      "dont_include_cv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dont Include CV"])},
      "estimated_sallary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Est. Salary"])},
      "explore_available_jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Browse Jobs & Apply Online"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seize new career opportunities in your field."])},
      "facilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facilities"])},
      "farthest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farthest"])},
      "founded_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Founded In"])},
      "freelance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freelance"])},
      "fulltime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fulltime"])},
      "hybrid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hybrid"])},
      "industry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Industry"])},
      "internship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internship"])},
      "insert_your_cv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Your CV"])},
      "delete_your_cv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Your CV"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
      "job_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Description"])},
      "job_requirement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Requirement"])},
      "latest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest"])},
      "magister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magister"])},
      "message_optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message(Optional)"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "no_vacancy_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Vacancy Found"])},
      "not_specified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Specified"])},
      "onsite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onsite"])},
      "parttime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parttime"])},
      "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Province"])},
      "related_vacancies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Related Vacancy"])},
      "remote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote"])},
      "sallary_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salary Range"])},
      "sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort"])},
      "submit_aplication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit Application"])},
      "till": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["till"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
      "unreal_works_made_by_petranesian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unreal works made by Petranesians"])},
      "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
      "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Url"])},
      "upload_new_cv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload New CV"])},
      "submit_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply Job"])},
      "vacancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Field"])},
      "visit_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visit Profile"])},
      "vacancy_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacancy System"])},
      "vacancy_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacancy Type"])},
      "view_company_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Company Profile"])},
      "visit_website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visit Website"])},
      "kinobi_modal": {
        "modal_body_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a CV using Kinobi CV Builder."])},
        "button_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Kinobi"])}
      }
    },
    "tutorial": {
      "button": {
        "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
        "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
        "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Understand"])}
      },
      "alumni": {
        "slide1": {
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["WELCOME, ", _interpolate(_named("user")), "!"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ready to get started? Swipe/click to start your tour"])}
        },
        "slide2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your PANNEL Dashboard"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access your Petranesian Alumni Card, create an easy ATS CV with Kinobi, and start subscription to get exclusive information about Career Center and OIA events - all in one dashboard!"])},
          "tooltip": {
            "kinobi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tell us about your professional journey!"])},
            "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click the subscribe button now to get up to date information!"])}
          }
        },
        "slide3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It's time to make your career dreams come true!"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find a job that suits you."])}
        },
        "slide4": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hidden gems by alumni entrepreneurs!"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display and offer your services and products to all Petranesian alumni here!"])}
        }
      },
      "company": {
        "slide1": {
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["WELCOME, ", _interpolate(_named("user")), "!"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ready to get started? Swipe/click to start your tour."])}
        },
        "slide2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your PANNEL Dashboard"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post vacancy information independently."])}
        },
        "slide3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post your job vacancy on our social media platform"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For wider reach and connection, post your job vacancy on our social media platforms. Check the approval column and upload your soft file poster to get posted on Petra Career Center's Instagram."])}
        },
        "slide4": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find Petranesians"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover the potentials you're looking for with “Open to Work” feature."])}
        }
      }
    }
  },
  "panel": {
    "product": {
      "add_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Product"])},
      "alumni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alumni"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
      "change_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Product"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue?"])},
      "create_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Product"])},
      "edit_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Product"])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
      "how_to_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How To Order"])},
      "insert_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Category"])},
      "insert_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Contact"])},
      "insert_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Location"])},
      "insert_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Product Price"])},
      "insert_product_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Product Image. Max file size is 1 MB and format .jpg & .png"])},
      "insert_product_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert product location"])},
      "insert_product_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Product Name"])},
      "insert_product_thumbnail_image_to_display_in_the_catalog_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert product thumbnail image to display in the catalog product"])},
      "insert_thumbnails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Thumbnails"])},
      "insert_photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Photo"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
      "no_product_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Product Added"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
      "product_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Description"])},
      "product_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Name"])},
      "related_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Related Product"])},
      "search_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Image"])},
      "you_will_delete_this_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will delete this product "])}
    },
    "vacancy": {
      "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepted"])},
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["active"])},
      "application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apllication"])},
      "application_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application Detail"])},
      "bachelor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bachelor"])},
      "choose_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select City"])},
      "choose_prody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Study Program"])},
      "choose_province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Province"])},
      "choose_vacancy_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Vacancy Position"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue?"])},
      "create_vacancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Vacancy"])},
      "declined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declined"])},
      "detail_applicant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Applicant"])},
      "diploma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diploma"])},
      "doctor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctor"])},
      "edit_vacancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Vacancy"])},
      "expired_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expired Date"])},
      "facilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facilities"])},
      "final_salary_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final Salary Range"])},
      "foreword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foreword"])},
      "freelance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freelance"])},
      "fulltime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fulltime"])},
      "hybrid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hybrid"])},
      "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inactive"])},
      "insert_final_salary_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Final Salary Range"])},
      "insert_reason_for_acceptance_rejection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert reason for acceptance/rejection"])},
      "insert_starting_salary_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Starting Salary Range"])},
      "internship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internship"])},
      "job_applicant_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Applicants List"])},
      "job_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Description"])},
      "job_requirement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Requirement"])},
      "list_of_applicants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List Of Applicants"])},
      "list_vacancies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List Vacancies"])},
      "magister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magister"])},
      "no_applicants_yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Applicants Yet"])},
      "no_vacancy_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Vacancy Found"])},
      "onsite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onsite"])},
      "parttime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Part-time"])},
      "prody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Study Program"])},
      "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Province"])},
      "reason_for_acceptance_rejection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason for acceptance/rejection"])},
      "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejected"])},
      "remote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote"])},
      "required_skill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required Skill"])},
      "sallary_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salary Range"])},
      "seen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seen"])},
      "starting_salary_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starting Salary Range"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
      "totals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total(s)"])},
      "vacancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacancy"])},
      "vacancy_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacancy List"])},
      "vacancy_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacancy Name"])},
      "vacancy_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacancy Position"])},
      "vacancy_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacancy Status"])},
      "vacancy_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacancy System"])},
      "vacancy_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacancy Type"])},
      "visit_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visit Profile"])},
      "waiting_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting For Confirmation"])},
      "you_will_approve_this_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will approve this application"])},
      "you_will_delete_this_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will delete this application"])},
      "you_will_reject_this_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will reject this application"])}
    }
  },
  "profile": {
    "open_sidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Sidebar"])},
    "open_profile_sidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Profile Menu"])},
    "company": {
      "menu": {
        "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile Perusahaan"])},
        "your_vacancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Vacancy"])},
        "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])}
      },
      "form_profile": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Profile"])},
        "upload_photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Photo"])},
        "choose_other_photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Other Photo"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Name"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter company name"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter company Email"])},
        "telp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. Telephone"])},
        "telp_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter company phone number"])},
        "since": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Since"])},
        "since_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the year of establishment"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
        "password_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Password"])},
        "password_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password Confirmation"])},
        "password_confirmation_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Password Confirmation"])},
        "password_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empty the password and password confirmation field otherwise want to change the password!"])},
        "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
        "bussines_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bussiness Field"])},
        "bussines_fields_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose bussiness field"])},
        "bussiness_scope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bussiness Scope"])},
        "bussiness_scope_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose bussiness scope"])},
        "bussiness_scope_item": {
          "local": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local"])},
          "national": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["National"])},
          "multinational": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multinational / International"])}
        },
        "total_employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Employee"])},
        "total_employee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter total employee"])},
        "work_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work Days per Week"])},
        "work_days_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter work days per week"])},
        "work_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Working Hours per Day"])},
        "work_hours_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter total working hours per day"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Company"])},
        "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
        "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Province"])},
        "province_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Province"])},
        "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
        "city_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose City"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Address"])},
        "address_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter full address"])},
        "contact_person_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Person"])},
        "contact_person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "contact_person_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Personal Contact Name"])},
        "contact_person_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
        "contact_person_position_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Personal Contact Position"])},
        "contact_person_telp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
        "contact_person_telp_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Personal Contact Phone Number"])},
        "contact_person_hp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handphone Number"])},
        "contact_person_hp_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Personal Contact Handphone Number"])},
        "social_media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social Media"])},
        "facebook_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Facebook ID"])},
        "twitter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Twitter ID"])},
        "instagram_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Instagram ID"])},
        "linkedin_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter LinkedIn ID"])},
        "tiktok_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Tiktok ID"])},
        "youtube_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Youtube ID"])},
        "save_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Profile"])}
      },
      "company_vacancy": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Vacancies"])},
        "add_vacancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Vacancy"])},
        "table_header": {
          "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacancy Position"])},
          "work_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work System"])},
          "vacancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applications"])},
          "range_salary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Range Salary"])},
          "expired_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Until"])},
          "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])}
        },
        "table_info": {
          "count_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application(s)"])},
          "status_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
          "status_non_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non Active"])},
          "tooltip_response_applications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Response Applications"])},
          "tooltip_change_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Active Status"])},
          "tooltip_see_vacancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See Vacancy"])},
          "tooltip_edit_vacancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Vacancy"])},
          "tooltip_delete_vacancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Vacancy"])}
        },
        "form": {
          "title_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Vacancy"])},
          "title_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Vacancy"])},
          "work_type_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type Job"])},
          "work_type": {
            "fulltime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fulltime"])},
            "parttime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parttime"])},
            "internship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internship"])},
            "freelance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freelance"])}
          },
          "level_education_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education Level"])},
          "level_education": {
            "diploma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diploma"])},
            "sarjana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bachelor"])},
            "magister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Master"])},
            "doktor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctor"])}
          },
          "program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program"])},
          "program_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Program"])},
          "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Position"])},
          "position_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Job Position"])},
          "salary_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Range Salary Start"])},
          "salary_start_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Range Salary Start"])},
          "salary_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Range Salary End"])},
          "salary_end_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Range Salary End"])},
          "expired_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Recruiting Until"])},
          "system_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job System"])},
          "system": {
            "onsite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onsite"])},
            "remote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote"])},
            "hybrid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hybrid"])}
          },
          "is_active_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status Vacancy"])},
          "is_active": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non Active"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])}
          ],
          "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Province"])},
          "province_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Province"])},
          "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
          "city_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose City"])},
          "required_skill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required Skill(s)"])},
          "job_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Description"])},
          "requirement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requirement"])},
          "facilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facilities"])},
          "save_vacancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Vacancy"])},
          "is_social_post": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want this vacancy to be uploaded on instagram (at)petracareercenter.job?"])},
          "file_social_post": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you want to upload more than one file, please put them in a folder then compress them with *zip or *rar format 'Posters are recommended in *png file format and square shape with a resolution of 1080x1080.'"])},
          "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])}
        }
      },
      "company_application": {
        "modal_title_step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List Applications"])},
        "modal_title_step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Application"])},
        "step1_list": {
          "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total(s)"])},
          "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Applicants yet"])},
          "status": {
            "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
            "seen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seen"])},
            "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approved"])},
            "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejected"])}
          },
          "view_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Response Application"])},
          "view_applicant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Applicant"])},
          "delete_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Applicant"])}
        },
        "step2_detail": {
          "status": {
            "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
            "seen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seen"])},
            "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approved"])},
            "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejected"])}
          },
          "level_education": {
            "diploma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diploma"])},
            "sarjana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bachelor"])},
            "magister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Master"])},
            "doktor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctor"])}
          },
          "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason for acceptance/rejection *"])},
          "tooltip_approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approve Applicant"])},
          "tooltip_reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject Applicant"])},
          "tooltip_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Applicant"])},
          "foreword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foreword"])},
          "status_swal": {
            "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will receive application from "])},
            "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will rejecte application from "])}
          },
          "text_swal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue?"])},
          "reason_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the reason for acceptance/rejection"])},
          "delete_swal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will delete application from "])},
          "no_cv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Cv Submitted"])}
        }
      }
    },
    "alumni": {
      "menu": {
        "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
        "education_activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education & Activities"])},
        "work_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work Experience"])},
        "your_business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Businesses"])},
        "your_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Products"])},
        "your_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Applications"])},
        "your_curriculum_vitaes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your CV"])},
        "bookmarked_job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bookmarked Jobs"])},
        "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])}
      },
      "card": {
        "digital": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Petranesian Alumni Card"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get yours today!"])},
          "view_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Card"])},
          "learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn More"])}
        },
        "tracer_study": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tracer Study"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are happy to know your feedback."])},
          "start_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Now"])}
        },
        "marchandise": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Petranesian Merchandise"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop official merchandise from Petranesian Shop."])},
          "shop_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop Now"])}
        }
      },
      "form_profile": {
        "secondary_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secondary Email"])},
        "credential": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Credential"])},
        "update_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Account"])},
        "work_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work Status"])},
        "work_status_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Work Status"])},
        "work_status_options": {
          "employed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employed"])},
          "unemployed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open To Work"])}
        },
        "open_for_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile Visibility"])},
        "open_for_company_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile Open For Company"])},
        "open_for_company_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile visibility settings make alumni data details visible to the company"])},
        "open_for_company_options": {
          "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
          "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])}
        },
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
        "address_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Address"])},
        "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Province"])},
        "province_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Province"])},
        "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
        "city_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose City"])},
        "university_detail_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["University Detail"])},
        "student_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identification Number"])},
        "student_id_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Identification Number"])},
        "generation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year of Entry"])},
        "generation_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Generation Year"])},
        "gpa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final GPA"])},
        "profile_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
        "upload_photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Photo"])},
        "choose_other_photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Other Photo"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Alumni Name"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Email"])},
        "birth_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birth Date"])},
        "birth_date_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Birth Date"])},
        "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
        "gender_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Gender"])},
        "gender_options": {
          "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
          "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])}
        },
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
        "password_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Password"])},
        "old_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old Password"])},
        "old_password_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Old Password"])},
        "password_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password Confirmation"])},
        "password_confirmation_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Password Confirmation"])},
        "password_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empty the password and password confirmation field otherwise want to change the password!"])},
        "contact_information_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Information"])},
        "no_telp_home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Phone Number"])},
        "no_telp_hp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cell Phone number"])},
        "social_media_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social Media"])},
        "facebook_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter ID Facebook"])},
        "twitter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter ID Twitter"])},
        "instagram_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter ID Instagram"])},
        "linkedin_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter ID LinkedIn"])},
        "tiktok_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter ID Tiktok"])},
        "youtube_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter ID Youtube"])},
        "skill_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skills"])},
        "skill_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Skill"])},
        "interest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interest"])},
        "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
        "save_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Profile"])}
      },
      "education_activities": {
        "education_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Educational Background"])},
        "add_education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Educational Background"])},
        "remove_education_swal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You Will Delete Education History at "])},
        "remove_education_text_swal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue?"])},
        "gpa_validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GPA must be in the number 0 - 4"])},
        "modal_education": {
          "gpa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final GPA"])},
          "gpa_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Final GPA"])},
          "program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program"])},
          "program_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Program Name"])},
          "title_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Educational background"])},
          "title_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Educational background"])},
          "institution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institution Name"])},
          "institution_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Institution Name"])},
          "level_education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level Education"])},
          "level_education_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Educational stage"])},
          "front_degree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Front Degree"])},
          "front_degree_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Front Degree"])},
          "back_degree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back Degree"])},
          "back_degree_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Back Degree"])},
          "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Year"])},
          "start_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Start Year"])},
          "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Year"])},
          "end_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter End Year"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education Description"])},
          "button_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
          "button_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])}
        },
        "table_education": {
          "institution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institution Name"])},
          "level_education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level Education"])},
          "gpa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GPA"])},
          "program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program"])},
          "front_degree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Front Degree"])},
          "back_degree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back Degree"])},
          "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
          "no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Education History"])}
        },
        "organization_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization Experience"])},
        "table_organization": {
          "organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization"])},
          "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
          "poin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poin"])},
          "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
          "no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Organizational History"])}
        },
        "activities_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lecture Activities"])},
        "table_activities": {
          "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity"])},
          "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
          "poin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poin"])},
          "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
          "no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No History of Lecture Activities"])}
        }
      },
      "work_history": {
        "tab_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work Experience"])},
        "add_work_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Work Experience"])},
        "remove_education_swal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You Will Delete Work Experience at "])},
        "remove_education_text_swal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue?"])},
        "table": {
          "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Name"])},
          "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
          "salary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salary"])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
          "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["current"])},
          "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
          "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
          "no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Work Experience"])}
        },
        "modal": {
          "title_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Work Experience"])},
          "title_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Work Experience"])},
          "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Name"])},
          "company_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Company Name"])},
          "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
          "position_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Job Position"])},
          "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Province"])},
          "province_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Province"])},
          "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
          "city_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose City"])},
          "start_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start at"])},
          "start_at_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Start Date"])},
          "finish_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish at"])},
          "finish_at_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Finish Date"])},
          "check_finish_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I'm Still Working Here"])},
          "salary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salary"])},
          "salary_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Last Salary"])},
          "salary_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave it blank if you don't want to display salary"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Description"])},
          "button_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
          "button_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
          "link_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter company link"])}
        }
      },
      "your_business": {
        "tab_agency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agency"])},
        "tab_freelance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freelance"])},
        "tab_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
        "agency": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
          "link_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter agency link"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agency"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provide detailed information about your business, including the services you offer, your past experience, and notable clients"])},
          "upload_photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Photo"])},
          "choose_other_photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Other Photo"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agency Name"])},
          "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Agency Name"])},
          "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
          "contact_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Phone Number"])},
          "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Province"])},
          "province_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Province"])},
          "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
          "city_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose City"])},
          "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
          "address_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Address"])},
          "scope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Scope"])},
          "scope_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Business Scope"])},
          "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee"])},
          "fee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Fee Agency"])},
          "fee_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave it blank if you don't want to display costs"])},
          "start_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start at"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agency Description"])},
          "button_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Agency Data"])},
          "button_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Agency Data"])},
          "swal_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You Will Delete This Agency"])},
          "swal_text_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue?"])}
        },
        "freelance": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
          "link_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan link freelance"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freelance"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List your areas of expertise or specialization in freelance work. This can be industry-specific, technologies or tools you are proficient in, or specific skills you possess"])},
          "upload_photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Photo"])},
          "choose_other_photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Other Photo"])},
          "expertise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expertise"])},
          "expertise_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Expertise"])},
          "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
          "location_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Location"])},
          "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee"])},
          "fee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Freelance Fee"])},
          "fee_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave it blank if you don't want to display costs"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freelance Description"])},
          "button_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Freelance"])},
          "button_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Freelance"])},
          "swal_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You Will Delete This Freelance"])},
          "swal_text_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue?"])}
        }
      }
    }
  },
  "global": {
    "level_education": {
      "diploma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diploma"])},
      "sarjana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bachelor"])},
      "magister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Master"])},
      "doktor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctor"])}
    },
    "bussiness_scope_item": {
      "local": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local"])},
      "national": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["National"])},
      "multinational": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multinational / International"])}
    },
    "choose_city_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select province first"])}
  }
}